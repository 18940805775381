<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="table-search">
          <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="公告标题" prop="noticeTitle">
              <el-input
                  v-model="queryParams.noticeTitle"
                  placeholder="请输入公告标题"
                  clearable
                  size="small"
                  @keyup.enter.native="handleQuery"
              />
            </el-form-item>
            <el-form-item label="操作人员" prop="createBy">
              <el-input
                  v-model="queryParams.createBy"
                  placeholder="请输入操作人员"
                  clearable
                  size="small"
                  @keyup.enter.native="handleQuery"
              />
            </el-form-item>
            <el-form-item label="类型" prop="noticeType">
              <el-select v-model="queryParams.noticeType" placeholder="公告类型" clearable size="small">
                <el-option
                    v-for="dict in typeOptions"
                    :key="dict.dictValue"
                    :label="dict.dictLabel"
                    :value="dict.dictValue"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
              <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <el-row :gutter="15" class="mb8">
          <el-col :span="1.5">
            <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd" v-if="userPermissions.indexOf('notice_create')>-1">
              新增
            </el-button>
          </el-col>
          <el-col :span="1.5"></el-col>
        </el-row>
        <div class="default-table">
          <el-table v-loading="loading" :data="noticeList" @selection-change="handleSelectionChange" border>
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="序号" align="center" prop="id" width="100" />
            <el-table-column
                label="公告标题"
                align="center"
                prop="notice_title"
                :show-overflow-tooltip="true"
            />
            <el-table-column
                label="公告内容"
                align="center"
                prop="notice_content"
                :show-overflow-tooltip="true"
            />
            <el-table-column
                label="公告类型"
                align="center"
                prop="notice_type"
                :formatter="typeFormat"
                width="100"
            />
            <el-table-column
                label="状态"
                align="center"
                prop="status"
                :formatter="statusFormat"
                width="100"
            />
            <el-table-column label="创建者" align="center" prop="creator" width="100" />
            <el-table-column label="创建时间" align="center" prop="created_at" width="100">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.created_at, '{y}-{m}-{d}') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    v-if="userPermissions.indexOf('notice_edit')>-1"
                    @click="handleUpdate(scope.row)"
                >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    v-if="userPermissions.indexOf('notice_delete')>-1"
                    @click="handleDelete(scope.row)"
                >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <pagination
              v-show="total>0"
              :total="total"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getList"
          />
        </div>

        <!-- 添加或修改公告对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="780px" append-to-body>
          <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="公告标题" prop="notice_title">
                  <el-input v-model="form.notice_title" placeholder="请输入公告标题" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="公告类型" prop="notice_type">
                  <el-select v-model="form.notice_type" placeholder="请选择">
                    <el-option
                        v-for="dict in typeOptions"
                        :key="dict.dictValue"
                        :label="dict.dictLabel"
                        :value="dict.dictValue"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="状态">
                  <el-radio-group v-model="form.status">
                    <el-radio
                        v-for="dict in statusOptions"
                        :key="dict.dictValue"
                        :label="dict.dictValue"
                    >{{ dict.dictLabel }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="内容">
                  <el-input v-model="form.notice_content" placeholder="公告内容" clearable />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer" style="padding-top:20px">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog>

      </div>

    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from "@/layouts/PageHeaderLayout";
import { mapGetters } from "vuex";

export default {
  name: "NoticeList",
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: {PageHeaderLayout},
  data() {
    return {
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 公告表格数据
      noticeList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 类型数据字典
      statusOptions: [
        {dictValue: 1, dictLabel: '正常'},
        {dictValue: 0, dictLabel: '停用'},
      ],
      // 状态数据字典
      typeOptions: [
        {dictValue: 1, dictLabel: '系统消息'},
        // {dictValue: 2, dictLabel: '业务公告'},
        // {dictValue: 0, dictLabel: '未发布'},
        // {dictValue: -1, dictLabel: '已下架'},
      ],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        noticeTitle: undefined,
        // createBy: undefined,
        status: undefined
      },
      // 表单参数
      form: {status: 1, notice_title: '', notice_type: ''},
      // 表单校验
      rules: {
        notice_title: [
          {required: true, message: "公告标题不能为空", trigger: "blur"}
        ],
        notice_type: [
          {required: true, message: "公告类型不能为空", trigger: "blur"}
        ]
      }
    };
  },
  created() {
    this.getList()
  },
  methods: {
    typeFormat( row, column ) {
      let val = this.typeOptions.find(value => value.dictValue === row[column.property])
      return val.dictLabel || '-'
      // return this.selectDictLabel(this.typeOptions, row[column.property])
    },
    statusFormat( row, column ) {
      let val = this.statusOptions.find(value => value.dictValue === row[column.property])
      return val.dictLabel || '-'
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.queryParams.status) {
        condition.status = this.queryParams.status
      }
      if (this.queryParams.noticeTitle) {
        condition.notice_title = this.queryParams.noticeTitle
      }
      return condition
    },
    /** 查询公告列表 */
    async getList() {
      this.loading = true;
      let pagingInfo = {current_page: this.queryParams.pageNum, page_size: this.queryParams.pageSize}
      let searchCondition = this.handleSearchCondition()
      // 合并
      // Object.assign(searchCondition, pagingInfo)
      Object.assign(searchCondition, pagingInfo)//{order: this.orderSort}
      let {list, pages} = await this.$api.listNotice(searchCondition)
      //     .then(response => {
      //   console.log(response)
      //   this.noticeList = response.list;
      //   let pages = response.pages;
      //   this.total = pages.total;
      //   // this.offset = pages.offset;
      //   this.loading = false;
      // });
      this.total = pages.total;
      this.$nextTick(() => {
        this.noticeList = list
      })
      this.loading = false;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        notice_title: undefined,
        notice_type: undefined,
        notice_content: undefined,
        status: 1
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange( selection ) {
      this.ids = selection.map(item => item.noticeId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加公告";
    },
    /** 修改按钮操作 */
    async handleUpdate( row ) {
      this.reset();
      const noticeId = row.id
      let {info} = await this.$api.getNotice(noticeId)
      if (info) {
        this.form = info
        this.open = true;
        this.title = "修改公告";
      }
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.formSubmit()

          // if (this.form.id != undefined) {
          //   this.$api.updateNotice(this.form).then(response => {
          //     if (response.code === 200) {
          //       this.msgSuccess("修改成功");
          //       this.open = false;
          //       this.getList();
          //     } else {
          //       this.msgError(response.msg);
          //     }
          //   });
          // } else {
          //   this.$api.addNotice(this.form).then(response => {
          //     if (response.code === 200) {
          //       this.msgSuccess("新增成功");
          //       this.open = false;
          //       this.getList();
          //     } else {
          //       this.msgError(response.msg);
          //     }
          //   });
          // }
        }
      });
    },

    async formSubmit() {
      if (this.form.id != undefined) {
        let id = await this.$api.updateNotice(this.form)
        if (id) {
          this.msgSuccess("修改成功");
          this.open = false;
          this.getList();
        }
      } else {
        let id = await this.$api.addNotice(this.form)
        if (id) {
          this.msgSuccess("新增成功");
          this.open = false;
          this.getList();
        }
      }
    },
    /** 删除按钮操作 */
    handleDelete( row ) {
      const noticeIds = row.id || this.ids
      this.$confirm('是否确认删除公告编号为"' + noticeIds + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        await this.$api.delNotice(noticeIds);
        this.msgSuccess("删除成功");
        await this.getList();

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
}
</script>

<style scoped>

</style>
